/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */

import getRpcUrl from 'utils/getRpcUrl'

export const CHAINS = {
  56: {
    chainId: `0x38`,
    chainName: 'BSC',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: [`https://bscscan.com/`],
  },
  10000 : {
    chainId: `0x2710`,
    chainName: 'smartBCH',
    nativeCurrency: {
      name: 'Smart Bitcoin Cash',
      symbol: 'SBCH',
      decimals: 18,
    },
    rpcUrls: [getRpcUrl()],
    blockExplorerUrls: [`https://smartscout.cash/`],
  }
}

export const switchChain = async (chainId) => {
  const provider = window.ethereum;
  if (provider && CHAINS[chainId]) {
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [CHAINS[chainId]],
    })
    return true;
  }
  return false;
}

export  const setupNetwork = async () => {
  const provider = window.ethereum
  if (provider) {
    try {
      await switchChain(56);
      return true
    } catch (error) {
      console.error('Failed to setup the network in Metamask:', error)
      return false
    }
  } else {
    console.error("Can't setup the smartBCH network on metamask because window.ethereum is undefined")
    return false
  }
}
export const connWallt = async (connectorId:string,connect:any,status:string) => {
          await connect(connectorId);
          if(status!=="connected"){
            const hasSetup = await setupNetwork();
            if (hasSetup) {connect(connectorId)}
          }
}

export default connWallt;